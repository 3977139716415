<template>
<!-- All Courses Start -->
<div class="section section-padding-02 pb-10">
    <div class="container">

        <!-- All Courses Top Start -->
        <div class="courses-top">

            <!-- Section Title Start -->
            <div class="section-title shape-01">
                <h2 class="main-title">All <span>Courses</span> of  Builders' Hub Vocational Academy</h2>
            </div>
            <!-- Section Title End -->

            <!-- Courses Search Start -->
            <div class="courses-search">
                <form action="#">
                    <input type="text" placeholder="Search your course" v-model="searchParam.category">
                    <button><i class="flaticon-magnifying-glass"></i></button>
                </form>
            </div>
            <!-- Courses Search End -->

        </div>
        <!-- All Courses Top End -->

        <!-- All Courses Tabs Menu Start -->
        <div class="courses-tabs-menu courses-active">
            <div class="swiper-container">
                <ul class="swiper-wrapper nav">
                    <li v-for="(Category,index) in Categories" :key="Category" class="swiper-slide">
                        <button 
                            :class="{ active: index == 0 }" 
                            data-bs-toggle="tab" 
                            :data-bs-target="`#tabs${index}`">{{ Category.name }}
                        </button>
                    </li>            
                </ul>
            </div>
            <!-- Add Pagination -->
            <div class="swiper-button-next"><i class="icofont-rounded-right"></i></div>
            <div class="swiper-button-prev"><i class="icofont-rounded-left"></i></div>
        </div>
        <!-- All Courses Tabs Menu End -->

        <!-- All Courses tab content Start -->
        <div class="tab-content courses-tab-content">
        <div class="tab-pane fade show active" id="tabs1">
            <!-- All Courses Wrapper Start -->
            <div class="courses-wrapper">
                <div class="row">
                    <div v-for="Course in AlCourses" :key="Course" class="col-lg-4 col-md-6">
                        <SingleCourse :course="Course"/>
                    </div>
                    
                </div>
        </div>
        <!-- All Courses Wrapper End -->
        </div>
        </div>
        <!-- All Courses BUtton Start -->
        <div v-if="this.$route.name != 'Courses'"  class="courses-btn text-center">
            <router-link :to='{name:"Courses"}' class="btn btn-secondary btn-hover-primary">Other Course</router-link>
        </div>
        <!-- All Courses BUtton End -->

    </div>
</div>
        <!-- All Courses End -->
        
</template>
<script>
import Swiper from '@/assets/js/plugins/swiper-bundle.min.js'
import SingleCourse from '@/components/SingleCourseCard.vue';
import plumbing from '@/assets/images/courses/plumbing.jpg'
import electrical from '@/assets/images/courses/electrical.jpg'
import capentary from '@/assets/images/courses/capentry.jpg'
import tiling from '@/assets/images/courses/tiling.jpg'
import construction from '@/assets/images/courses/construction.jpg'
import masonry from '@/assets/images/courses/mason.jpg'
import facility from '@/assets/images/courses/facility.jpg'
import heater from '@/assets/images/courses/heater.jpg'
import welding from '@/assets/images/courses/welding.jpg'
import interior1 from '@/assets/images/courses/interior1.jpg'
import interior2 from '@/assets/images/courses/interior2.jpg'
import author from '@/assets/images/courses/author.png'
import painting from '@/assets/images/courses/painting.jpg'
import mech from '@/assets/images/courses/mech.jpg'
import solar from '@/assets/images/courses/solar.png'

export default{
    name:"Allcourses",
    components:{
        SingleCourse,
    },
    data(){
        return{
            searchParam: {
                category: '',
                course: '',
            },
            Courses:[
                {name:'Plumbing',category:"Plumbing",authorName:'',authorQualification:"B TECH",authorImage:author,image:plumbing,countRating:"200",description:"Foundation plumbing course",Curriculum:"Plumbing course",lectures:"39",time:"08 hr 15 mins",price:"200,000",rating:"4.0",level: "Foundation"},
                {name:'Masonry',category:"Masonry",authorName:'',authorQualification:"B TECH",authorImage:author,image:masonry,countRating:"200",description:"Masonry course",Curriculum:"Masonry course",lectures:"39",time:"08 hr 15 mins",price:"200,000",rating:"4.0",level: "Foundation"},
                {name:'Electrical Installations',category:"Electrical",authorName:'',authorQualification:"B TECH",authorImage:author,image:electrical,countRating:"200",description:"Electrical course",Curriculum:"Electrical course",lectures:"39",time:"08 hr 15 mins",price:"200,000",rating:"4.0",level: "Foundation"},
                {name:'Carpentry/Furniture Making',category:"Carpentry",authorName:'',authorQualification:"B TECH",authorImage:author,image:capentary,countRating:"200",description:"Carpentry course",Curriculum:"Carpentry course",lectures:"39",time:"08 hr 15 mins",price:"200,000",rating:"5.0",level: "Foundation"},
                {name:'Interior Design (Basic)',category:"Interior D.",authorName:'',authorQualification:"B TECH",authorImage:author,image:interior1,countRating:"200",description:"Basic Interior design course",Curriculum:"Interior design course (Basic)",lectures:"39",time:"08 hr 15 mins",price:"200,000",rating:"1.0",level: "Foundation"},
                {name:'Interior Design (Master Class)',category:"Interior D.",authorName:'',authorQualification:"B TECH",authorImage:author,image:interior2,countRating:"200",description:"Master class Interior design course",Curriculum:"Interior design course (Masterclass)",lectures:"39",time:"08 hr 15 mins",price:"200,000",rating:"2.0",level: "Foundation"},
                {name:'Tiling',category:"Masonry",authorName:'',authorQualification:"B TECH",authorImage:author,image:tiling,countRating:"200",description:"Tiling course",Curriculum:"Tiling course",lectures:"39",time:"08 hr 15 mins",price:"200,000",rating:"3.5",level: "Foundation"},
                {name:'Solar Design and Installation',category:"Electrical",authorName:'',authorQualification:"B TECH",authorImage:author,image:solar,countRating:"200",description:"Solar Design and Installation",Curriculum:"Solar Design and Installation",lectures:"39",time:"08 hr 15 mins",price:"200,000",rating:"3.5",level: "Foundation"},
                {name:'Painting',category:"Painting",authorName:'',authorQualification:"B TECH",authorImage:author,image:painting,countRating:"200",description:"Painting course",Curriculum:"Painting course",lectures:"39",time:"08 hr 15 mins",price:"200,000",rating:"3.5",level: "Foundation"},
                {name:'Facility Maintenance',category:"Maintenance",authorName:'',authorQualification:"B TECH",authorImage:author,image:facility,countRating:"200",description:"Facility Maintenance course",Curriculum:"Facility Maintenance course",lectures:"39",time:"08 hr 15 mins",price:"200,000",rating:"3.5",level: "Foundation"},
                {name:'Welding & Fabrication (GTAW/OAW) ',category:"Construction",authorName:'',authorQualification:"B TECH",authorImage:author,image:welding,countRating:"200",description:"Welding and Fabrication course",Curriculum:"Welding and Fabrication course (GTAW/OAW) ",lectures:"39",time:"08 hr 15 mins",price:"300,000",rating:"3.5",level: "Foundation"},
                {name:'Welding & Fabrication (SMAW) ',category:"Construction",authorName:'',authorQualification:"B TECH",authorImage:author,image:welding,countRating:"200",description:"Welding and Fabrication course",Curriculum:"Welding and Fabrication course (SMAW) ",lectures:"39",time:"08 hr 15 mins",price:"300,000",rating:"3.5",level: "Foundation"},
                {name:'Welding & Fabrication (GMAW) ',category:"Construction",authorName:'',authorQualification:"B TECH",authorImage:author,image:welding,countRating:"200",description:"Welding and Fabrication course",Curriculum:"Welding and Fabrication course (GMAW) ",lectures:"39",time:"08 hr 15 mins",price:"300,000",rating:"3.5",level: "Foundation"},
                {name:'Welding & Fabrication (FCAW) ',category:"Construction",authorName:'',authorQualification:"B TECH",authorImage:author,image:welding,countRating:"200",description:"Welding and Fabrication course",Curriculum:"Welding and Fabrication course (FCAW) ",lectures:"39",time:"08 hr 15 mins",price:"300,000",rating:"3.5",level: "Foundation"},
                {name:'Heating Ventilation & Air Conditioning',category:"Electrical",authorName:'',authorQualification:"B TECH",authorImage:author,image:heater,countRating:"200",description:"Heating Ventilation & Air Conditioning course",Curriculum:"Heating Ventilation & Air Conditioning course",lectures:"39",time:"08 hr 15 mins",price:"200,000",rating:"3.5",level: "Foundation"},
                {name:'Mechanical, Electrical and Plumbing',category:"General",authorName:'',authorQualification:"B TECH",authorImage:author,image:mech,countRating:"200",description:"Mechanical Electrical and Plumbing course",Curriculum:"Mechanical Electrical and Plumbing course",lectures:"39",time:"08 hr 15 mins",price:"200,000",rating:"3.5",level: "Foundation"},
                {name:'Building Construction & Supervision',category:"Construction",authorName:'',authorQualification:"B TECH",authorImage:author,image:construction,countRating:"200",description:"Building Constructioin & Supervision course",Curriculum:"Building Constructioin & Supervision course",lectures:"39",time:"08 hr 15 mins",price:"200,000",rating:"3.5",level: "Foundation"},
            ],
        }
    },
    methods: {
        courseSwiper(){
             var edule = new Swiper('.courses-active .swiper-container', {
                speed: 600,
                spaceBetween: 30,        
                navigation: {
                    nextEl: '.courses-active .swiper-button-next',
                    prevEl: '.courses-active .swiper-button-prev',
                },       
                breakpoints: {
                    0: {
                        slidesPerView: 1,
                    },
                    576: {
                        slidesPerView: 2,
                    },  
                    768: {
                        slidesPerView: 3,
                    },            
                    992: {
                        slidesPerView: 4,
                    },            
                    1200: {
                        slidesPerView: 5,
                    }
                },
            });
        }
    },
    computed:{
         Categories(){
            return this.$store.state.Categories
        },
         AlCourses(){
            return this.Courses.filter(course => course.name.toLowerCase().includes(this.searchParam.course.toLowerCase()) ||  course.category.toLowerCase().includes(this.searchParam.category.toLowerCase()))
        }
    },
    mounted(){
        this.courseSwiper();
    }
}
</script>